import React from "react"
import LayoutA3 from "../../components/layout-a3"
import EnrollStep from "../../components/enroll-step"
import enrollStyles from "./a3_enroll.module.css"

export default class A3EnrollStep extends React.Component {
  render() {
    const color='#7fc527'
    const src='/demos/themeA3/enroll'
    return(
      <LayoutA3 >
        <div className={enrollStyles.enrollContainer}>
          <EnrollStep color={color} src={src}/>
        </div>
      </LayoutA3>
    )
  }
}